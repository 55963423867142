





























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { debounce } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataCoa } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import { Component, Vue } from "vue-property-decorator";

const ExpenseAccountSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
});

@Component
export default class ExpenseAccountSelect extends ExpenseAccountSelectProps {
  accountOption = {
    data: [] as DataCoa[],
    search: "",
    fetching: true,
    initialData: [] as DataCoa[],
  };

  async created() {
    await this.getAccountList(true);
    if (this.value && !this.accountOption.data.find(c => c.id === this.value)) {
      this.fetchMoreAccount();
    }
  }

  async getAccountList(firstLoad = false) {
    try {
      this.accountOption.fetching = true;
      const EXPENSE_ACCOUNT_PREFIX = 6;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: `active~true_AND_code~${EXPENSE_ACCOUNT_PREFIX}*_AND_isParent~false`,
      };
      if (this.accountOption.search) {
        const expenseAccSearch = `${EXPENSE_ACCOUNT_PREFIX}${this.accountOption.search}`;
        param.search = `code~${expenseAccSearch}*_OR_description~*${this.accountOption.search}*_AND_active~true_AND_isParent~false`;
      }
      const res = await settingsServices.listOfCoa(param, "");
      this.accountOption.data = res.data;
      if (firstLoad) {
        this.accountOption.initialData = res.data;
      }
      this.accountOption.fetching = false;
    } catch (error) {
      this.accountOption.fetching = false;
    }
  }

  searchAccount(value: string): void {
    debounce(() => {
      this.accountOption.search = value;
      this.getAccountList();
    });
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  onMeta(value: DataCoa): void {
    this.$emit("meta", value);
  }

  resetOptionList(): void {
    this.accountOption.data = this.accountOption.initialData;
  }

  /**
   * Fetch coa list from API until matching with @this value
   */
  async fetchMoreAccount() {
    try {
      this.accountOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
        search: "active~true_AND_code~6*",
      };
      while (
        !this.accountOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const accountOptions = await settingsServices.listOfCoa(param, "");
        totalPage = Math.ceil(accountOptions.totalElements / 20);
        this.accountOption.data = this.accountOption.data.concat(
          accountOptions.data
        );
        param.page++;
      }
      this.accountOption.fetching = false;
    } catch (error) {
      this.accountOption.fetching = false;
    }
  }
}
